import React from "react";
import "./Hero.css";

function Hero() {
  return (
    <>
      <div className="hero">
        <div className="hero-container">
          <div className="description">
            <div className="select-non">
              <h1 className="hero-headding heading" id="hero-headding">
                Exclusive VPS Hosting Offer
              </h1>
              <p className="hero-detail detail">
                High-Perfomance <span className="cyan">100%</span> VPS Hosting
                Plans
              </p>
              <p className="hero-detail detail">
                Starting from <span className="rupee">₹ 600/ month</span>
              </p>
              <p className="hero-detail detail">
                <span className="cyan">High-Performance</span> Servers
              </p>
              <p className="hero-detail detail">
                Scalable Resources to Fit Your Needs
              </p>
            </div>
            <button
              className="btn"
              id="btn"
              onClick={() =>
                window.open(
                  "https://billing.hostifyra.com/order/main/index/vps"
                )
              }

              // src="https://billing.hostifyra.com/order/main/index/vps"
            >
              Elevate your hosting experience today
            </button>
          </div>
          <div>
            <img
              src="\assets\Images\Cloud hosting-pana 1.png"
              alt=""
              className="server-img"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
