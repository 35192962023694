import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <>
      <div className="get_started">
        <h1>High-Perfomance Hosting Solutions Only ₹600/mo</h1>
        <button
          className="get_started_btn"
          onClick={() => {
            window.location.href =
              "https://billing.hostifyra.com/order/main/index/vps";
          }}
        >
          Get Started{" "}
        </button>
      </div>
      <div className="footer_cont">
        <div className="footer-main ">
          <div className="footer-container">
            <div className="footer-info foot-section">
              <div className="img">
                <img
                  src="\assets\Images\foot-logo.png"
                  alt=""
                  className="foot-logo"
                />
              </div>
              <div className="foot-para">
                We are Hostifyra 10 years of experience on this field with most
                talanted peoples and leaders.
              </div>
              <div className="foot-social">
                <a href="" aria-label="read more on facebook">
                  <i className="fa-brands fa-facebook foot-img"></i>
                </a>
                <a href="" aria-label="read more on twitter">
                  <i className="fa-brands fa-twitter foot-img"></i>
                </a>
                <a href="" aria-label="know more on pintrest">
                  <i className="fa-brands fa-pinterest foot-img"></i>
                </a>
                <a href="" arial-lable="know more on google plus ">
                  <i className="fa-brands fa-google-plus foot-img"></i>
                </a>
              </div>
            </div>
            <div className="quick_links">
              <div className="footer-links foot-section">
                <div className="footer-heading">Quick Links</div>
                <ul className="foot-ul">
                  <li>
                    <a href="https://billing.hostifyra.com/plugin/support_manager/client_tickets">
                      Support
                    </a>
                  </li>
                  <li>
                    <a href="https://billing.hostifyra.com/client/login">
                      My Account
                    </a>
                  </li>
                  <li>
                    <a href="Terms_of_Use.html">Terms of Use</a>
                  </li>
                </ul>
              </div>
              <div className="footer-service foot-section">
                <div className="footer-heading">Services</div>
                <ul className="foot-ul">
                  <li>
                    <a href="https://billing.hostifyra.com/plugin/support_manager/client_tickets">
                      Web Hosting
                    </a>
                  </li>
                  <li>
                    <a href="https://billing.hostifyra.com/plugin/support_manager/client_tickets">
                      VPS Hosting
                    </a>
                  </li>
                  <li>
                    <a href="https://billing.hostifyra.com/plugin/support_manager/client_tickets">
                      Dedicated Server
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-contect foot-section">
                <div className="footer-heading">Contacts</div>
                <ul className="foot-ul">
                  <li className="contact-li">
                    <i className="fa fa-phone-alt foot-img"></i>
                    <span>+91-3767968838</span>
                  </li>
                  <li>
                    <i
                      className="fa fa-envelope foot-img"
                      aria-hidden="true"
                      style={{ color: "" }}
                    ></i>
                    <span>sales@hostifyra.com</span>
                  </li>
                  <li>
                    <i className="fa fa-map-marker foot-img"></i>
                    <span>Jorhat Assam, INDIA</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="foot-offer">
            <div className="offer-left">
              <a href=""> We offer 4 Days Trial</a>
              We offer 4 Days Trial No card details asked only the terms and
              conditions may apply <a href="">Click here.</a>
            </div>
            <div>
              <img
                src="\assets\Images\foot-offer-img.png"
                alt=""
                className="foot-offer-img"
              />
            </div>
          </div>
          <div className="foot-end">
            <div className="copyright">
              &copy; 2024 Hostifyra all right reserved.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
