import React, { useState } from "react";
import Card from "../Card/Card";
import "./GeneralVps.css";

function GeneralVps() {
  const [isAnnual, setIsAnnual] = useState(false);
  const subscription = {
    month: [
      {
        image: `\\assets\\Images\\computer.png`,
        system: `Windows`,
        price: `1000`,
        cpu: `2`,
        ram: `2`,
        graphics: `40`,
        bandwidth: `10 TB bandwidth`,
        speed: `500 Mbps`,
        server: `Windows server 2019/2022`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=3`,
        plan: `Month`,
      },
      {
        image: `\\assets\\Images\\computer.png`,
        system: `Windows`,
        price: `4999`,
        cpu: `8`,
        ram: `32`,
        graphics: `300`,
        bandwidth: `unmetered bandwidth`,
        speed: `1 Gbps`,
        server: `Windows server 2019/2022`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=3`,
        plan: `Month`,
      },
      {
        image: `\\assets\\Images\\linusPe.png`,
        system: `Linux`,
        price: `600`,
        cpu: `1`,
        ram: `1`,
        graphics: `50`,
        bandwidth: `10 TB bandwidth`,
        speed: `500 Mbps`,
        server: `Linux`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=4`,
        plan: `Month`,
      },
      {
        image: `\\assets\\Images\\linusPe.png`,
        system: `Linux`,
        price: `4500`,
        cpu: `8`,
        ram: `32`,
        graphics: `3000`,
        bandwidth: `unmetered bandwidth`,
        speed: `1 Gbps`,
        server: `Linux`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=4`,
        plan: `Month`,
      },
    ],
    annual: [
      // You can add corresponding annual values here
      {
        image: `\\assets\\Images\\computer.png`,
        system: `Windows`,
        price: `10000`,
        cpu: `2`,
        ram: `2`,
        graphics: `40`,
        bandwidth: `10 TB bandwidth`,
        speed: `500 Mbps`,
        server: `Windows server 2019/2022`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=3`,
        plan: `Annual`,
      },
      {
        image: `\\assets\\Images\\computer.png`,
        system: `Windows`,
        price: `58000`,
        cpu: `8`,
        ram: `32`,
        graphics: `300`,
        bandwidth: `Unmetered bandwidth`,
        speed: `1 Gbps`,
        server: `Windows server 2019/2022`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=3`,
        plan: `Annual`,
      },
      {
        image: `\\assets\\Images\\linusPe.png`,
        system: `Linux`,
        price: `7000`,
        cpu: `1`,
        ram: `1`,
        graphics: `50`,
        bandwidth: `10 TB bandwidth`,
        speed: `500 Mbps`,
        server: `Linux`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=4`,
        plan: `Annual`,
      },
      {
        image: `\\assets\\Images\\linusPe.png`,
        system: `Linux`,
        price: `52000`,
        cpu: `8`,
        ram: `32`,
        graphics: `3000`,
        bandwidth: `unmetered bandwidth`,
        speed: `1 Gbps`,
        server: `Linux`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=4`,
        plan: `Annual`,
      },
    ],
  };

  const handleToggle = () => {
    setIsAnnual(!isAnnual);
  };

  return (
    <>
      <div className="toggel-cont">
        <div className="tog-first-heading">General VPS</div>
        <div className="tog-headding headding-toggel">
          <span>Hostifyra </span> Delivers the Best Value for Your Hosting
          Needs.
        </div>
        <p className="para">
          Get more for less with Hostifyra. Our premium hosting solutions offer
          unmatched performance, security, and support—all at a price that works
          for you. Elevate your hosting experience without stretching your
          budget.
        </p>
        <div className="tog-cont toggle-container">
          <span className="label">Month</span>

          <label className="toggle-switch">
            <input type="checkbox" id="toggleSwitch" onChange={handleToggle} />
            <span className="slider"></span>
          </label>

          <span className="label">Annual</span>
        </div>
      </div>

      <div className="card-container-1">
        <Card
          className="Swipe-1"
          subscription={isAnnual ? subscription.annual : subscription.month}
        />
      </div>

      <div className="t-cont toggel-cont">
        <div className="headding-toggel">
          <span>Hostifyra </span> use new latest hardware.
        </div>
        <p className="paraa para">
          All our servers are equipped with New and latest hardware - DDR5/DDR4
          RAM and Enterprise NVMe.
        </p>
      </div>
    </>
  );
}

export default GeneralVps;
