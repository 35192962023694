import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Services.css";

function Services() {
  const settings = {
    additionalTransfrom: 0,
    arrows: true,
    autoPlay: true,
    autoPlaySpeed: 3000,
    centerMode: false,
    className: "",
    containerClass: "wrap-slider-container",
    dotListClass: "",
    draggable: true,
    focusOnSelect: false,
    infinite: true,
    itemClass: "",
    keyBoardControl: true,
    minimumTouchDrag: 80,
    renderButtonGroupOutside: false,
    renderDotsOutside: false,
    responsive: {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 1024 },
        items: 4,
        slidesToSlide: 1,
      },
      desktop: {
        breakpoint: { max: 1024, min: 600 },
        items: 2,
        slidesToSlide: 1,
      },
      tablet: {
        breakpoint: { max: 600, min: 480 },
        items: 2,
        slidesToSlide: 1,
      },
      mobile: {
        breakpoint: { max: 480, min: 0 },
        items: 1,
        slidesToSlide: 1,
      },
    },
    showDots: true,
    sliderClass: "",
    slidesToSlide: 1,
    swipeable: true,
  };

  return (
    <div className="wrap-slider-wrapper">
      <div className="wrap-card-list">
        <Carousel {...settings}>
          {data.map((d, index) => (
            <div className="wrap-card-item" key={index}>
              <div className="wrap-title">{d.title}</div>
              <img src={d.img} alt="" className="wrap-img" />
              <div className="wrap-heading-card wrap-heading">{d.heading}</div>
              <div className="wrap-para">{d.para}</div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

const data = [
  {
    title: `Performance`,
    img: "\\assets\\Images\\Rocket.png",
    heading: `Unlock Unmatched Performance With HostyFyra VPS Hosting`,
    para: `Hostifyra, we understand the importance of speed and uptime for your business. Our VPS hosting solutions are designed to deliver lightning-fast performance, ensuring that your website or application runs smoothly without interruptions. Whether you're running a high-traffic e-commerce site or a resource-intensive application, our VPS plans give you the power and control you need`,
  },
  {
    title: `Flexible Plans`,
    img: `\\assets\\Images\\flexible.png`,
    heading: `Flexible Plans Tailored to Your Needs`,
    para: `No two businesses are the same, and neither are their hosting requirements. That's why we offer a range of VPS hosting plans that can be tailored to your specific needs. Effortlessly scale your resources up or down with the flexibility to adapt as your business grows.`,
  },
  {
    title: `Security`,
    img: `\\assets\\Images\\security.png`,
    heading: `Security You Can Trust`,
    para: `Your data is safe with us. Hostifyra provides top-notch security features, including DDoS protection, automated backups, and advanced firewalls. Rest easy knowing that your information is protected 24/7 by our state-of-the-art security measures.`,
  },
  {
    title: `Why HostiFyra`,
    img: `\\assets\\Images\\whyhosti.png`,
    heading: `Unlock Unmatched Performance With HostyFyra VPS Hosting`,
    para: `Your data is safe with us. Hostifyra provides top-notch security features, including DDoS protection, automated backups, and advanced firewalls. Rest easy knowing that your information is protected 24/7 by our state-of-the-art security measures.`,
  },
];

export default Services;
