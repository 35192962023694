import React from "react";
import "./Trail.css";

function Trail() {
  return (
    <>
      <div className="container">
        <div className="small-text">Experience the Power of Hostifyra</div>
        <div className="big">
          <div className="big-text">Ready to elevate your hosting game?</div>
          <div className="big-text">Try Hostifyra Today.</div>
        </div>
        <button
          className="btn btn-trail"
          onClick={() =>
            window.open(
              "https://billing.hostifyra.com/order/main/packages/vps/?group_id=8"
            )
          }
          // src="https://billing.hostifyra.com/order/main/packages/vps/?group_id=8"
        >
          Try 4 Days Trial for Free
        </button>
      </div>
    </>
  );
}

export default Trail;
