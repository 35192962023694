import React, { useEffect } from "react";
import "./ChatBot.css";

function ChatBot() {
  useEffect(() => {
    // Create the first script element
    const script1 = document.createElement("script");
    script1.innerHTML = `
          window.$zoho = window.$zoho || {};
          $zoho.salesiq = $zoho.salesiq || { ready: function () { } };
        `;
    document.body.appendChild(script1);

    // Create the second script element
    const script2 = document.createElement("script");
    script2.id = "zsiqscript";
    script2.src =
      "https://salesiq.zohopublic.in/widget?wc=siqdbb2c53448bf669785528d49f3398106816e39405e5f4fcb365591fad74e2be3";
    script2.defer = true;
    document.body.appendChild(script2);

    // Cleanup function to remove the scripts when the component unmounts
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  return (
    <div className="Message-cont">
      <div className="contact-message"></div>
    </div>
  );
}

export default ChatBot;
