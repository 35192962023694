import React, { useState } from "react";
import "./TradingVps.css";
import Card from "../Card/Card";
import GeneralVps from "./GeneralVps";

function TradingVps() {
  const [isAnnual, setIsAnnual] = useState(false);

  const subscription = {
    month: [
      {
        image: `\\assets\\Images\\computer.png`,
        system: "Windows T1", // Updated from original
        price: "1000", // Updated from original
        cpu: "2", // Updated from original
        ram: "6", // Updated from original
        graphics: `40`,
        bandwidth: `10 TB bandwidth`,
        speed: `500 Mbps`,
        server: `Windows server 2019/ ***`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=7`,
        plan: `Month`,
      },
      {
        image: `\\assets\\Images\\computer.png`,
        system: "Windows T2", // Updated from original
        price: "1400", // Updated from original
        cpu: "2", // Updated from original
        ram: "8", // Updated from original
        graphics: `300`,
        bandwidth: `10 TB bandwidth`,
        speed: `500 Mbps`,
        server: `Windows server 2019 ***`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=7`,
        plan: `Month`,
      },
      {
        image: `\\assets\\Images\\computer.png`,
        system: "Windows T3", // Updated from original
        price: "1600", // Updated from original
        cpu: "4", // Updated from original
        ram: "8", // Updated from original
        graphics: `50`,
        bandwidth: `10 TB bandwidth`,
        speed: `500 Mbps`,
        server: `Windows server 2019 ***`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=7`,
        plan: `Month`,
      },
      {
        image: `\\assets\\Images\\computer.png`,
        system: "Windows T4", // Updated from original
        price: "1900", // Updated from original
        cpu: "4", // Updated from original
        ram: "12", // Updated from original
        graphics: `3000`,
        bandwidth: `10 TB bandwidth`,
        speed: `500 Mbps`,
        server: `Windows server 2019 ***`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=7`,
        plan: `Month`,
      },
    ],
    annual: [
      {
        image: `\\assets\\Images\\computer.png`,
        system: "Windows T1", // Updated from original
        price: "11000", // Updated from original
        cpu: "2", // Updated from original
        ram: "6", // Updated from original
        graphics: `40`,
        bandwidth: `10 TB bandwidth`,
        speed: `500 Mbps`,
        server: `Windows server 2019/ ***`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=7`,
        plan: `Annual`,
      },
      {
        image: `\\assets\\Images\\computer.png`,
        system: "Windows T2", // Updated from original
        price: "15800", // Updated from original
        cpu: "2", // Updated from original
        ram: "8", // Updated from original
        graphics: `300`,
        bandwidth: `10 TB bandwidth`,
        speed: `500 Mbps`,
        server: `Windows server 2019 ***`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=7`,
        plan: `Annual`,
      },
      {
        image: `\\assets\\Images\\computer.png`,
        system: "Windows T3", // Updated from original
        price: "18000", // Updated from original
        cpu: "4", // Updated from original
        ram: "8", // Updated from original
        graphics: `50`,
        bandwidth: `10 TB bandwidth`,
        speed: `500 Mbps`,
        server: `Windows server 2019 ***`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=7`,
        plan: `Annual`,
      },
      {
        image: `\\assets\\Images\\computer.png`,
        system: "Windows T4", // Updated from original
        price: "21000", // Updated from original
        cpu: "4", // Updated from original
        ram: "12", // Updated from original
        graphics: `3000`,
        bandwidth: `10 TB bandwidth`,
        speed: `500 Mbps`,
        server: `Windows server 2019 ***`,
        link: `https://billing.hostifyra.com/order/main/packages/vps/?group_id=7`,
        plan: `Annual`,
      },
    ],
  };

  const handleToggle = () => {
    setIsAnnual(!isAnnual);
  };

  return (
    <>
      <div className="cont-t container">
        <div className="trad-heading heading">Trading VPS</div>
        <div className="tog-cont toggle-container">
          <span className="label">Month</span>

          <label className="toggle-switch">
            <input type="checkbox" id="toggleSwitch" onChange={handleToggle} />
            <span className="slider"></span>
          </label>

          <span className="label">Annual</span>
        </div>
      </div>

      <div className="card-container-1">
        <Card
          className="Swipe-1"
          subscription={isAnnual ? subscription.annual : subscription.month}
        />
      </div>

      <div className="cont-t container">
        <GeneralVps />
      </div>
    </>
  );
}

export default TradingVps;
