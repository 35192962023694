import "./App.css";
import ChatBot from "./Components/Bot/ChatBot";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Hero from "./Components/Hero/Hero";
import TradingVps from "./Components/Pricing/TradingVps";
import Services from "./Components/Services/Services";
import Slide from "./Components/Slide/Slide";
import Trail from "./Components/Trail/Trail";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Trail />
      <Slide />

      <TradingVps />
      <Services />
      <ChatBot />
      <Footer />
    </div>
  );
}

export default App;
