import React, { useEffect } from "react";
import "./Header.css";

function Header() {
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById("navbar");
      const loginBtn = document.getElementById("login");
      if (window.scrollY > 0) {
        navbar.classList.add("scrolled");
        loginBtn.classList.add("black_text");
      } else {
        navbar.classList.remove("scrolled");
        loginBtn.classList.remove("black_text");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="header" id="navbar">
      <nav className="nav-bar">
        <div className="Nav-left">
          <div className="nav-contact">
            <a href="">
              <div className="call-img">
                <img
                  src="/assets/Images/head-phone.png"
                  alt=""
                  className="nav-call"
                />
                <span>+91</span>
                <span>-3767968838</span>
              </div>
            </a>
            <a href="">
              <div className="call-img">
                <img
                  src="/assets/Images/logo-mail.png"
                  alt=""
                  className="nav-mail"
                />
                <span>sales@hostifyra.com</span>
              </div>
            </a>
          </div>
          <div className="logo">
            <img
              src="/assets/Images/foot-logo.png"
              alt=""
              className="logo-img"
            />
          </div>
        </div>
        <div className="user">
          <a
            href="https://billing.hostifyra.com/client/login/"
            className="login_btn"
            id="login"
          >
            Login
          </a>
        </div>
      </nav>
    </header>
  );
}

export default Header;
