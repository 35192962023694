import React from "react";
import "./Slide.css";

function Slide() {
  const src = [
    "/assets/SliddingImages/arch.png",
    "/assets/SliddingImages/cent.png",
    "/assets/SliddingImages/fedora.png",
    "/assets/SliddingImages/alma.png",
    "/assets/SliddingImages/freebsd.png",
    "/assets/SliddingImages/rocky.png",
    "/assets/SliddingImages/ubuntu.png",
    "/assets/SliddingImages/windows.png",
  ];

  return (
    <div className="wraper">
      <div className="Marque">
        <div className="Marquee-Group">
          {src.map((e, index) => (
            <div className="Image-Group" key={index}>
              <img src={e} alt="" className="Image" />
            </div>
          ))}
          {src.map((e, index) => (
            <div className="Image-Group" key={index + src.length}>
              <img src={e} alt="" className="Image" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Slide;
